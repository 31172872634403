import React, { useRef, useState, useEffect } from "react";
import ReportsLayout from "./ReportsLayout";
import ReportsNavBar from "./ReportsNavBar";
import AllChartsScreen from "./AllChartsScreen";
import CompareTrendsScreen from "./CompareTrendsScreen";
import { Spin } from "antd";
import {
  useCompareWeeklyTrends,
  useGetAssigneeTimeOnProjects,
  useGetTaskVsBugCountPerProject,
  useGetTaskVsBugCountPerUser,
  useGetTaskVsBugTimePerProject,
  useGetTaskVsBugTimePerUser,
  useGetTeamAllocationPerProject,
  useGetTeamTimeOnProjects,
  useGetTenantInsights,
  useGetTotalTimeSpentByTeams,
  useGetTotalTimeVsEstimationPerProject,
  useGetTotalTimeVsEstimationPerUser,
} from "../../Core/redux/ReactQueryHooksV3/useReportsAPI";
import { useAppSelector } from "../../Core/redux/hooks";

const getUniqueKeys = (data, excludeKeys = ["project"]) => {
  const uniqueKeysSet = new Set();

  // Ensure data is an array
  const dataset = Array.isArray(data) ? data : [];

  // Extract unique keys, excluding the specified keys (e.g., 'project')
  dataset.forEach((item) => {
    Object.keys(item).forEach((key) => {
      if (!excludeKeys.includes(key)) {
        uniqueKeysSet.add(key);
      }
    });
  });

  // Convert Set to Array and return the unique keys
  return Array.from(uniqueKeysSet);
};
const ReportsScreenV3 = () => {
  const [screen, setScreen] = useState(1);
  const chartContainerRef = useRef(null);
  const chartContainerRef1 = useRef(null);
  const chartContainerRef2 = useRef(null);

  // Redux state to track selected charts
  const {
    selectedCharts,
    weeks,
    selectProjects,
    selectAssignees,
    selectTeams,
    compareTrendsWeeks,
    selectAssigneesTrends,
    selectProjectsTrends,
  } = useAppSelector((state) => state.dashboard);

  // API hook to get tenant insights
  const { data, isLoading, error } = useGetTenantInsights();

  // const selectedCharts1 = [
  //   {
  //     type: 1,
  //     title: "Total Time Estimated vs Time Spent",
  //     indexByX: "project",
  //     indexByY: "Hours",
  //     toolTip:
  //       "Compare the estimated time with the actual time spent on tasks to assess project performance during the selected week(s).",
  //     chartType: "CustomBulletChart",
  //     keys: ["estimatedTime", "total_time_spent"],
  //     data: [
  //       {
  //         projectName: "Website Redesign",
  //         estimatedTime: 17,
  //         total_time_spent: 16,
  //       },
  //       {
  //         projectName: "Mobile App",
  //         estimatedTime: 24,
  //         total_time_spent: 25,
  //       },
  //     ],
  //   },
  //   {
  //     type: 2,
  //     title: "Team Time on Projects",
  //     indexByX: "project",
  //     indexByY: "Hours",
  //     toolTip:
  //       "View the time spent by each team on different projects during the selected week(s).",
  //     chartType: "DividedBarChart",
  //     data: [
  //       {
  //         project: "Website Redesign",
  //         "Design Team": 3,
  //         "Dev Team": 20,
  //         "QA Team": 2,
  //         "Business Team": 2,
  //       },
  //       {
  //         project: "Mobile App",
  //         "Design Team": 4,
  //         "Dev Team": 8,
  //         "QA Team": 35,
  //       },
  //       {
  //         project: "API Development",
  //         "Design Team": 15,
  //         "Dev Team": 10,
  //         "QA Team": 4,
  //       },
  //       {
  //         project: "E-commerce Platform",
  //         "Design Team": 25,
  //         "Dev Team": 6,
  //         "QA Team": 5,
  //       },
  //       {
  //         project: "Machine Learning Model",
  //         "Design Team": 5,
  //         "Dev Team": 12,
  //         "QA Team": 6,
  //       },
  //     ],
  //     keys: [
  //       "Design Team",
  //       "Dev Team",
  //       "QA Team",
  //       "Design Team0",
  //       "Dev Teamo",
  //       "QA Teamo",
  //       "Design Teama",
  //       "Dev Teama",
  //       "QA Teama",
  //       "Business Team",
  //     ],
  //   },
  //   {
  //     type: 3,
  //     title: "Task vs Bug Ratio",
  //     indexByX: "project",
  //     indexByY: "Numbers",
  //     toolTip:
  //       "View the ratio of the number of tasks to bugs within projects during the selected week(s).",
  //     chartType: "GroupedBarChart",
  //     data: [
  //       { project: "Antarctic Website", tasks: 10, bugs: 20 },
  //       { project: "Website", tasks: 11, bugs: 25 },
  //       { project: "Pebble", tasks: 24, bugs: 35 },
  //       { project: "PROJECT V2", tasks: 10, bugs: 18 },
  //       { project: "HHS", tasks: 12, bugs: 15 },
  //       { project: "New Project A", tasks: 15, bugs: 30 },
  //       { project: "New Project B", tasks: 22, bugs: 28 },
  //       { project: "New Project C", tasks: 18, bugs: 22 },
  //       { project: "New Project D", tasks: 30, bugs: 25 },
  //       { project: "New Project E", tasks: 9, bugs: 17 },
  //       { project: "New Project F", tasks: 28, bugs: 22 },
  //       { project: "New Project G", tasks: 20, bugs: 25 },
  //       { project: "New Project H", tasks: 19, bugs: 17 },
  //     ],
  //     keys: ["tasks", "bugs"],
  //   },
  //   {
  //     type: 4,
  //     title: "Task vs Bug Time Ratio",
  //     indexByX: "project",
  //     indexByY: "Hours",
  //     toolTip:
  //       "View the ratio of time spent on tasks to time spent on bugs within projects during the selected week(s).",
  //     chartType: "GroupedBarChart",
  //     data: [
  //       { project: "Antarctic Website", tasks: 15, bugs: 18 },
  //       { project: "Website", tasks: 24, bugs: 26 },
  //       // { project: "Pebble", tasks: 17, bugs: 28 },
  //       // { project: "PROJECT V2", tasks: 13, bugs: 21 },
  //       // { project: "HHS", tasks: 16, bugs: 25 },
  //     ],
  //     keys: ["tasks", "bugs"],
  //   },
  //   {
  //     type: 5,
  //     title: "Team Allocation",
  //     indexByX: "project",
  //     indexByY: "Members",
  //     toolTip:
  //       "View the allocation of teams on different projects during the selected week(s).",
  //     chartType: "DividedBarChart",
  //     data: [
  //       {
  //         project: "Website Redesign",
  //         "Design Team": 5,
  //         "Dev Team": 20,
  //         "QA Team": 10,
  //         "Business Team": 5,
  //       },
  //       {
  //         project: "Mobile App",
  //         "Design Team": 8,
  //         "Dev Team": 10,
  //         "QA Team": 15,
  //       },
  //     ],
  //     keys: ["Design Team", "Dev Team", "QA Team", "Business Team"],
  //   },
  //   {
  //     type: 6,
  //     title: "Total Time Spent by Teams",
  //     indexByX: "team",
  //     indexByY: "Hours",
  //     toolTip:
  //       "View the total time spent by each assignee within teams during the selected week(s).",
  //     chartType: "DividedBarChart",
  //     data: [
  //       {
  //         team: "Design",
  //         Alice: 10,
  //         Bob: 15,
  //         Charlie: 8,
  //       },
  //       {
  //         team: "Development",
  //         David: 12,
  //         Eve: 18,
  //         Frank: 16,
  //       },
  //       {
  //         team: "QA",
  //         Grace: 14,
  //         Heidi: 9,
  //         Ivan: 11,
  //       },
  //     ],
  //     keys: [
  //       "Alice",
  //       "Bob",
  //       "Charlie",
  //       "David",
  //       "Eve",
  //       "Frank",
  //       "Grace",
  //       "Heidi",
  //       "Ivan",
  //     ],
  //   },
  //   {
  //     type: 7,
  //     title: "Total Time Estimated vs Time Spent (Tasks)",
  //     indexByX: "assignee",
  //     indexByY: "Hours",
  //     toolTip:
  //       "Compare the estimated time with the actual time spent on tasks to assess project performance during the selected week(s).",
  //     chartType: "CustomBulletChart",
  //     keys: ["Time estimated", "Time spent"],
  //     data: [
  //       {
  //         assignee: "Sam",
  //         "Time estimated": 20,
  //         "Time spent": 16,
  //       },
  //       {
  //         assignee: "Rita",
  //         "Time estimated": 30,
  //         "Time spent": 20,
  //       },
  //     ],
  //   },
  //   {
  //     type: 8,
  //     title: "Assignee Time on Projects",
  //     indexByX: "assignee",
  //     indexByY: "Hours",
  //     toolTip:
  //       "View the time spent by each assignee on different projects during the selected week(s).",
  //     chartType: "DividedBarChart",
  //     data: [
  //       {
  //         assignee: "Alice",
  //         "Website Redesign": 10,
  //         "API Development": 40,
  //       },
  //       {
  //         assignee: "Bob",
  //         "Website Redesign": 15,
  //         "API Development": 5,
  //       },
  //       {
  //         assignee: "Charlie",
  //         "Website Redesign": 30,
  //         "API Development": 10,
  //       },
  //     ],
  //     keys: ["Website Redesign", "API Development"],
  //   },
  //   {
  //     type: 9,
  //     title: "Task vs Bug Ratio",
  //     indexByX: "assignee",
  //     indexByY: "Numbers",
  //     toolTip:
  //       "View the ratio of the number of tasks to bugs for each assignee during the selected week(s).",
  //     chartType: "GroupedBarChart",
  //     data: [
  //       { assignee: "Alice", tasks: 15, bugs: 18 },
  //       { assignee: "Bob", tasks: 24, bugs: 26 },
  //       { assignee: "Charlie", tasks: 17, bugs: 28 },
  //       { assignee: "David", tasks: 13, bugs: 21 },
  //       { assignee: "Eve", tasks: 16, bugs: 25 },
  //     ],
  //     keys: ["tasks", "bugs"],
  //   },
  //   {
  //     type: 10,
  //     title: "Task vs Bug Time Ratio",
  //     indexByX: "assignee",
  //     indexByY: "Hours",
  //     toolTip:
  //       "View the ratio of time spent on tasks to time spent on bugs for each assignee during the selected week(s).",
  //     chartType: "GroupedBarChart",
  //     data: [
  //       { assignee: "Alice", tasks: 15, bugs: 25 },
  //       { assignee: "Bob", tasks: 8, bugs: 12 },
  //       { assignee: "Charlie", tasks: 5, bugs: 9 },
  //       { assignee: "David", tasks: 6, bugs: 8 },
  //       { assignee: "Dan", tasks: 11, bugs: 15 },
  //     ],
  //     keys: ["tasks", "bugs"],
  //   },
  // ];

  console.log(
    !(
      weeks?.length === 1 &&
      weeks[0]?.startDate === "" &&
      weeks[0]?.endDate === ""
    ),
    "first load fetch "
  );

  // Extract required data for each chart using query hooks
  const { data: totalTimeVsEstimationPerProject, isLoading: isLoading1 } =
    useGetTotalTimeVsEstimationPerProject(
      weeks,
      selectProjects,
      selectAssignees,
      selectTeams,
      {
        enabled:
          selectedCharts?.chart1 &&
          weeks.length > 0 &&
          !(
            weeks.length === 1 &&
            weeks[0]?.startDate === "" &&
            weeks[0]?.endDate === ""
          ),
      }
    );

  const { data: teamTimeOnProjects, isLoading: isLoading2 } =
    useGetTeamTimeOnProjects(
      weeks,
      selectProjects,
      selectAssignees,
      selectTeams,
      {
        enabled:
          selectedCharts?.chart2 &&
          weeks.length > 0 &&
          !(
            weeks.length === 1 &&
            weeks[0]?.startDate === "" &&
            weeks[0]?.endDate === ""
          ),
      }
    );

  const { data: taskVsBugCountPerProject, isLoading: isLoading3 } =
    useGetTaskVsBugCountPerProject(
      weeks,
      selectProjects,
      selectAssignees,
      selectTeams,
      {
        enabled:
          selectedCharts?.chart3 &&
          weeks.length > 0 &&
          !(
            weeks.length === 1 &&
            weeks[0]?.startDate === "" &&
            weeks[0]?.endDate === ""
          ),
      }
    );

  const { data: taskVsBugTimePerProject, isLoading: isLoading4 } =
    useGetTaskVsBugTimePerProject(
      weeks,
      selectProjects,
      selectAssignees,
      selectTeams,
      {
        enabled:
          selectedCharts?.chart4 &&
          weeks.length > 0 &&
          !(
            weeks.length === 1 &&
            weeks[0]?.startDate === "" &&
            weeks[0]?.endDate === ""
          ),
      }
    );

  const { data: teamAllocationPerProject, isLoading: isLoading5 } =
    useGetTeamAllocationPerProject(
      weeks,
      selectProjects,
      selectAssignees,
      selectTeams,
      {
        enabled:
          selectedCharts?.chart5 &&
          weeks.length > 0 &&
          !(
            weeks.length === 1 &&
            weeks[0]?.startDate === "" &&
            weeks[0]?.endDate === ""
          ),
      }
    );

  const { data: totalTimeSpentByTeams, isLoading: isLoading6 } =
    useGetTotalTimeSpentByTeams(
      weeks,
      selectProjects,
      selectAssignees,
      selectTeams,
      {
        enabled:
          selectedCharts?.chart6 &&
          weeks.length > 0 &&
          !(
            weeks.length === 1 &&
            weeks[0]?.startDate === "" &&
            weeks[0]?.endDate === ""
          ),
      }
    );

  const { data: totalTimeVsEstimationPerUser, isLoading: isLoading7 } =
    useGetTotalTimeVsEstimationPerUser(
      weeks,
      selectProjects,
      selectAssignees,
      selectTeams,
      {
        enabled:
          selectedCharts?.chart7 &&
          weeks.length > 0 &&
          !(
            weeks.length === 1 &&
            weeks[0]?.startDate === "" &&
            weeks[0]?.endDate === ""
          ),
      }
    );

  const { data: assigneeTimeOnProjects, isLoading: isLoading8 } =
    useGetAssigneeTimeOnProjects(
      weeks,
      selectProjects,
      selectAssignees,
      selectTeams,
      {
        enabled:
          selectedCharts?.chart8 &&
          weeks.length > 0 &&
          !(
            weeks.length === 1 &&
            weeks[0]?.startDate === "" &&
            weeks[0]?.endDate === ""
          ),
      }
    );

  const { data: taskVsBugCountPerUser, isLoading: isLoading9 } =
    useGetTaskVsBugCountPerUser(
      weeks,
      selectProjects,
      selectAssignees,
      selectTeams,
      {
        enabled:
          selectedCharts?.chart9 &&
          weeks.length > 0 &&
          !(
            weeks.length === 1 &&
            weeks[0]?.startDate === "" &&
            weeks[0]?.endDate === ""
          ),
      }
    );

  const { data: taskVsBugTimePerUser, isLoading: isLoading10 } =
    useGetTaskVsBugTimePerUser(
      weeks,
      selectProjects,
      selectAssignees,
      selectTeams,
      {
        enabled:
          selectedCharts?.chart10 &&
          weeks.length > 0 &&
          !(
            weeks.length === 1 &&
            weeks[0]?.startDate === "" &&
            weeks[0]?.endDate === ""
          ),
      }
    );

  const filteredWeeks = data?.filteredWeeks || [];

  // Get the start and end dates for the selected weeks
  const weekData = compareTrendsWeeks?.map((week) => {
    const weekInfo =
      filteredWeeks.find((weekData) => weekData.weekName === week) || {};
    return {
      week,
      startDate: weekInfo?.startDate || "",
      endDate: weekInfo?.endDate || "",
    };
  });

  // Call the hook for each selected week separately
  const { data: trendData1, isLoading: isLoading11 } = useCompareWeeklyTrends(
    weekData[0]?.startDate && weekData[0]?.endDate
      ? weekData[0]?.startDate
      : "",
    weekData[0]?.startDate && weekData[0]?.endDate ? weekData[0]?.endDate : "",
    selectProjectsTrends || [],
    selectAssigneesTrends || [],
    screen === 2 &&
      weekData[0]?.startDate &&
      weekData[0]?.endDate &&
      compareTrendsWeeks?.length > 0
  );

  const { data: trendData2, isLoading: isLoading12 } = useCompareWeeklyTrends(
    weekData[1]?.startDate && weekData[1]?.endDate
      ? weekData[1]?.startDate
      : "",
    weekData[1]?.startDate && weekData[1]?.endDate ? weekData[1]?.endDate : "",
    selectProjectsTrends || [],
    selectAssigneesTrends || [],
    screen === 2 &&
      weekData[1]?.startDate &&
      weekData[1]?.endDate &&
      compareTrendsWeeks?.length > 0
  );

  const { data: trendData3, isLoading: isLoading13 } = useCompareWeeklyTrends(
    weekData[2]?.startDate && weekData[2]?.endDate
      ? weekData[2]?.startDate
      : "",
    weekData[2]?.startDate && weekData[2]?.endDate ? weekData[2]?.endDate : "",
    selectProjectsTrends || [],
    selectAssigneesTrends || [],
    screen === 2 &&
      weekData[2]?.startDate &&
      weekData[2]?.endDate &&
      compareTrendsWeeks?.length > 0
  );

  console.log(trendData1, " combino", trendData2, "3", trendData3);

  console.log(selectedCharts, "selectedCharts");

  const selectedChartsData = [
    {
      type: 1,
      title: "Total Time Estimated vs Time Spent",
      indexByX: "project",
      indexByY: "Hours",
      toolTip:
        "Compare the estimated time with the actual time spent during the selected period to assess project performance during the selected week(s).",
      chartType: "CustomBulletChart",
      keys: ["Time estimated", "Time spent"],
      data: totalTimeVsEstimationPerProject || [],
      weeks: weeks?.length || 1,
    },
    {
      type: 2,
      title: "Team Time on Projects",
      indexByX: "project",
      indexByY: "Hours",
      toolTip:
        "View the time spent by each team on different projects during the selected week(s).",
      chartType: "DividedBarChart",
      data: teamTimeOnProjects || [],
      // data: [
      //   {
      //     project: "Website Redesign",
      //     "Design Team": 3,
      //   },
      // ],
      keys: getUniqueKeys(teamTimeOnProjects || [], [
        "project",
        "max",
        "totalTime",
        "height",
      ]),
      // keys: ["Design Team"],
    },
    {
      type: 3,
      title: "Task vs Bug Ratio",
      indexByX: "project",
      indexByY: "Numbers",
      toolTip:
        "View the ratio of tasks to bugs within projects during the selected week(s). A higher ratio indicates more tasks being completed compared to bugs reported.",
      chartType: "GroupedBarChart",
      data: taskVsBugCountPerProject || [],
      keys: ["tasks", "bugs"],
    },
    {
      type: 4,
      title: "Task vs Bug Time Ratio",
      indexByX: "project",
      indexByY: "Hours",
      toolTip:
        "View the ratio of time spent on tasks to time spent on bugs within projects during the selected week(s).",
      chartType: "GroupedBarChart",
      data: taskVsBugTimePerProject || [],
      keys: ["tasks", "bugs"],
    },
    {
      type: 5,
      title: "Team Allocation",
      indexByX: "project",
      indexByY: "Members",
      toolTip:
        "View the allocation of teams on different projects during the selected week(s).",
      chartType: "DividedBarChart",
      data: teamAllocationPerProject || [],
      keys: getUniqueKeys(teamAllocationPerProject || [], [
        "project",
        "totalTime",
        "max",
        "height",
      ]),
    },
    {
      type: 6,
      title: "Total Time Spent by Teams",
      indexByX: "team",
      indexByY: "Hours",
      toolTip:
        "View the total time spent by each assignee within teams during the selected week(s).",
      chartType: "DividedBarChart",
      data: totalTimeSpentByTeams || [],
      keys: getUniqueKeys(totalTimeSpentByTeams || [], [
        "team",
        "max",
        "totalTime",
        "height",
      ]),
    },
    {
      type: 7,
      title: "Total Time Estimated vs Time Spent (Per Assignee)",
      indexByX: "assignee",
      indexByY: "Hours",
      toolTip:
        "Compare the estimated time with the actual time spent by the assignee during the selected period to assess project performance during the selected week(s).",
      chartType: "CustomBulletChart",
      keys: ["Time estimated", "Time spent"],
      data: totalTimeVsEstimationPerUser || [],
    },
    {
      type: 8,
      title: "Assignee Time on Projects (Per Assignee)",
      indexByX: "assignee",
      indexByY: "Hours",
      toolTip:
        "View the time spent by each assignee on different projects during the selected week(s).",
      chartType: "DividedBarChart",
      data: assigneeTimeOnProjects || [],
      keys: getUniqueKeys(assigneeTimeOnProjects || [], [
        "assignee",
        "max",
        "totalTime",
        "height",
        "projectSum",
      ]),
    },
    {
      type: 9,
      title: "Task vs Bug Ratio (Per Assignee)",
      indexByX: "assignee",
      indexByY: "Numbers",
      toolTip:
        "View the ratio of tasks to bugs within projects during the selected week(s). A higher ratio indicates more tasks being completed compared to bugs reported.",
      chartType: "GroupedBarChart",
      data: taskVsBugCountPerUser || [],
      keys: ["tasks", "bugs"],
    },
    {
      type: 10,
      title: "Task vs Bug Time Ratio (Per Assignee)",
      indexByX: "assignee",
      indexByY: "Hours",
      toolTip:
        "View the ratio of time spent on tasks to time spent on bugs for each assignee during the selected week(s).",
      chartType: "GroupedBarChart",
      data: taskVsBugTimePerUser || [],
      keys: ["tasks", "bugs"],
    },
  ].filter((chart, index) => selectedCharts[`chart${index + 1}`]);

  return (
    <ReportsLayout>
      <Spin spinning={isLoading}>
        <ReportsNavBar
          screen={screen}
          setScreen={setScreen}
          chartRef={chartContainerRef}
          data={data}
          ref1={chartContainerRef1}
          ref2={chartContainerRef2}
        />

        <div
          ref={chartContainerRef}
          className="bg-gray-50 h-[calc(100vh-110px)] overflow-y-scroll"
        >
          {screen === 1 && (
            <AllChartsScreen
              selectedCharts={selectedChartsData}
              overview={data}
              isLoading={
                (isLoading1 ||
                  isLoading2 ||
                  isLoading3 ||
                  isLoading4 ||
                  isLoading5 ||
                  isLoading6 ||
                  isLoading7 ||
                  isLoading8 ||
                  isLoading9 ||
                  isLoading10) &&
                !isLoading
              }
              ref1={chartContainerRef1}
              ref2={chartContainerRef2}
            />
          )}
          {screen === 2 && (
            <Spin
              spinning={
                (isLoading11 || isLoading12 || isLoading13) && !isLoading
              }
            >
              <CompareTrendsScreen
                column1={
                  selectAssigneesTrends.length > 0 &&
                  compareTrendsWeeks.length > 0 &&
                  weekData[0]?.startDate &&
                  weekData[0]?.endDate &&
                  trendData1
                    ? trendData1
                    : {}
                }
                column2={
                  selectAssigneesTrends.length > 0 &&
                  compareTrendsWeeks.length > 0 &&
                  weekData[1]?.startDate &&
                  weekData[1]?.endDate &&
                  trendData2
                    ? trendData2
                    : {}
                }
                column3={
                  selectAssigneesTrends.length > 0 &&
                  compareTrendsWeeks.length > 0 &&
                  weekData[2]?.startDate &&
                  weekData[2]?.endDate &&
                  trendData3
                    ? trendData3
                    : {}
                }
              />
            </Spin>
          )}
        </div>
      </Spin>
    </ReportsLayout>
  );
};

export default ReportsScreenV3;
